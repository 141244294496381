<template >
  <ReadCards />
</template>

<script>
import ReadCards from './components/ReadCards.vue'

export default {
  name: 'App',
  components: {
    ReadCards
  }
}
</script>

<style>
#app {
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
