<template class="">
  <ConfirmAction
    v-if="showConfirm"
    :confirmMessage="confirmMessage"
    @confirmed="onConfirmed"
    @canceled="onCanceled"
  />
 <div class="w-full h-full p-4 max-w-2xl flex justify-center items-center">
  <div class="out-cards">
   <div cards class="cards flex flex-wrap justify-center p-4">
    <div
     v-for="(card, id) in cardCounts"
     :key="id"
     class="card flex w-1/5 items-center justify-center flex-col p-2"
    >
     <div
      class="card-img align-middle items-center flex w-full flex-col text-center py-4
      justify-center font-extrabold text-3xl bg-white p-2 cursor-pointer border-2
      rounded-xl text-black
      border-cyan-100 hover:border-gray-500 hover:bg-slate-200
      active:border-gray-500 active:bg-blue-200
      "
      @click="countCard(id)"
     >
     <span class="w-full">{{ card.name }}</span>
     <img class="flex w-3/5" src="../assets/spades-icon.png" alt="">
     </div>
     <span
      type="number"
      class="w-8 mt-1 text-center justify-center flex aspect-square bg-white p-2"
      >{{ card.count }}</span
     >
    </div>
   </div>
   <div class="controll-section bg-gradient-to-r from-rose-100 to-teal-100 p-4 rounded-lg">
    <div class="controlls-top flex flex-row items-center justify-center">
     <div class="controll w-1/5 text-center flex items-center flex-col p-2 ">
      <label class="text-center font-semibold" for="">Decks</label>
      <input
       type="number"
       v-model="numDecks"
       min="1"
       max="8"
       id="numDecks"
       class="border-4 border-blue-700 w-full text-center justify-center flex aspect-square text-xl"
      />
     </div>

     <div class="controll w-1/5 text-center flex items-center flex-col p-2">
      <label class="text-center font-semibold" for="">Decks Remaining</label>
      <div
       type="number"
       class="border-4 text-center justify-center border-blue-700 w-full flex items-center aspect-square text-xl bg-white"
      >
       {{ numDecksRemaining }}
      </div>
     </div>

     <div class="controll w-1/5 text-center flex items-center flex-col p-2">
      <label class="text-center font-semibold" for="">Played Cards</label>
      <div
       type="number"
       class="border-4 text-center justify-center border-blue-700 w-full flex items-center aspect-square text-xl bg-white"
      >
       {{ totalCardsPlayed }}
      </div>
     </div>

     <div class="controll w-1/5 text-center flex items-center flex-col p-2">
      <label class="text-center font-semibold" for="">Cards Remaining</label>
      <div
       type="number"
       class="border-4 text-center justify-center border-blue-700 w-full flex items-center aspect-square text-xl bg-white"
      >
       {{ cardsRemaining }}
      </div>
     </div>
    </div>
    <div class="controlls-bottom flex items-center justify-around">
     <div class="controll text-center flex items-center flex-col w-1/5">
      <button
       @click="preBackMove"
       :class="[
        {'from-gray-400':(Object.keys(undoStack).length == 0)},
        {'to-gray-500':(Object.keys(undoStack).length == 0)},
        {'hover:bg-gray-500':(Object.keys(undoStack).length == 0)},
        {'border-gray-500':(Object.keys(undoStack).length == 0)},
        {'border-red-700':(Object.keys(undoStack).length > 0)},
        {'hover:bg-red-700':(Object.keys(undoStack).length > 0)},
        {'from-rose-700':(Object.keys(undoStack).length > 0)},
        {'to-pink-600':(Object.keys(undoStack).length > 0)},
        'bg-gradient-to-r', 'text-white', 'font-bold', 'py-2', 'px-4', 'border', 'rounded', 'back'
        ]"
      >
       Back Move
      </button>
     </div>
     <div class="controll text-center flex  flex-col w-1/5">
      <label class="text-center text-xl font-extrabold" for="">COUNT</label>
      <div
       type="number"
       :class="['border-4', 'text-center', 'justify-center', 'border-blue-700', 'w-full', 'flex', 'items-center', 'aspect-square' ,'text-3xl','font-extrabold', 'aspect-square',
       {'text-white':(cardCount<0)},{'bg-red-700':(cardCount<0)}, {'bg-green-500':(cardCount>0)},{'bg-white':(cardCount==0)}]"
      >{{ cardCount }}</div>
     </div>

     <div class="controll text-center flex flex-col w-1/5">
      <label class="text-center text-xl font-extrabold" for=""
       >TRUE COUNT</label
      >
      <div
       type="number"
       :class="['border-4', 'text-center', 'justify-center', 'border-blue-700', 'w-full', 'flex', 'items-center', 'aspect-square' ,'text-3xl','font-extrabold', 'aspect-square',{'text-white':(trueCount<0)},{'bg-red-700':(trueCount<0)}, {'bg-green-500':(trueCount>0)},{'bg-white':(trueCount==0)}]"
      >{{ trueCount }}</div>
     </div>
     <div class="controll text-center flex items-center flex-col w-1/5">
      <button
       @click="preClearAll"
       class="bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded clear"
      >
       Clear All
      </button>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>


<script>

import ConfirmAction from "../components/ConfirmAction.vue";

let cardCountsArr = [
    {
     name: "A",
     count: 0,
     valor: -1,
    },
    {
     name: "K",
     count: 0,
     valor: -1,
    },
    {
     name: "Q",
     count: 0,
     valor: -1,
    },
    {
     name: "J",
     count: 0,
     valor: -1,
    },
    {
     name: "10",
     count: 0,
     valor: -1,
    },
    {
     name: "9",
     count: 0,
     valor: 0,
    },
    {
     name: "8",
     count: 0,
     valor: 0,
    },
    {
     name: "7",
     count: 0,
     valor: 0,
    },
    {
     name: "6",
     count: 0,
     valor: 1,
    },
    {
     name: "5",
     count: 0,
     valor: 1,
    },
    {
     name: "4",
     count: 0,
     valor: 1,
    },
    {
     name: "3",
     count: 0,
     valor: 1,
    },
    {
     name: "2",
     count: 0,
     valor: 1,
    },
];

if( 'cardCountsArr' in window.localStorage ){
  cardCountsArr = JSON.parse(window.localStorage.cardCountsArr);
}

export default {
 name: "ReadCards",
 components: {
    ConfirmAction,
  },
 data() {
  return {
    showConfirm: false,
    numDecks: 8,
    cardsList: "",
    cardCounts: cardCountsArr,
    undoStack: [],
    confirmMessage: "",
    confirmCallBack: () => {
    }
  };
 },
 computed: {
  cardCount(){
    let allCards = this.cardCounts;
    let newcardCount = 0;
    for (const cardKey in allCards) {
     const theCard = allCards[cardKey];
     newcardCount += (theCard.count * theCard.valor);
    }
    return newcardCount;
  },
  trueCount(){
    return (this.cardCount/this.numDecksRemaining).toFixed(2);
  },
  totalCardsPlayed(){
    let allCards = this.cardCounts;
    let totalCardsP = 0;
    for (const cardKey in allCards) {
     const theCard = allCards[cardKey];
     totalCardsP += theCard.count;
    }
    return totalCardsP;
  },
  cardsRemaining() {
   return this.numDecks * 52 - this.totalCardsPlayed;
  },
  numDecksRemaining() {
   return (this.cardsRemaining / 52).toFixed(2);
  },
 },
 methods: {
  onConfirmed(){
    this.confirmCallBack();
    this.showConfirm = false;
  },
  preClearAll(){
    this.showConfirm = true;
    this.confirmMessage = "reiniciar el conteo";
    this.confirmCallBack = this.clearAll;
  },
  preBackMove(){
    if (this.undoStack.length > 0) {
      this.showConfirm = true;
    this.confirmMessage = "regresar el ultimo movimiento";
      this.confirmCallBack = this.undoMove;
   }
  },
  countCard(cardType) {
   if( this.cardCounts[cardType].count < (this.numDecks*4)){    
    this.cardCounts[cardType].count++;
    this.undoStack.push({ cardType });
    window.localStorage.cardCountsArr = JSON.stringify(this.cardCounts);
   }
  },
  clearAll() {
    this.cardCounts = [ { name: "A", count: 0, valor: -1, }, { name: "K", count: 0, valor: -1, }, { name: "Q", count: 0, valor: -1, }, { name: "J", count: 0, valor: -1, }, { name: "10", count: 0, valor: -1, }, { name: "9", count: 0, valor: 0, }, { name: "8", count: 0, valor: 0, }, { name: "7", count: 0, valor: 0, }, { name: "6", count: 0, valor: 1, }, { name: "5", count: 0, valor: 1, }, { name: "4", count: 0, valor: 1, }, { name: "3", count: 0, valor: 1, }, { name: "2", count: 0, valor: 1, }, ];
    window.localStorage.cardCountsArr = JSON.stringify(this.cardCounts);
    this.showConfirm = false;
  },
  onCanceled(){
    this.showConfirm = false;
  },
  undoMove() {
   if (this.undoStack.length > 0) {
    const lastMove = this.undoStack.pop();
    const { cardType } = lastMove;
    this.cardCounts[cardType].count--;
   }
  },
 },
 watch: {},
};
</script>