<template>
 <div
  class="fixed w-full h-full bg-gray-500 left-0 top-0 bg-opacity-75 flex justify-center items-center"
 >
  <div class="text-2xl bg-blue-900 text-white font-semibold rounded-lg p-4 w-1/3 min-w-[25rem] flex flex-col">
   <p class="">¿Estás seguro que deseas {{  confirmMessage  }}?</p>
   <div class="flex row-auto justify-around py-4 mt-4">
    <button @click="confirm()"  class="py-2 px-4 border bg-red-600 hover:bg-red-500 active:bg-red-700 text-white rounded back">Si</button>
    <button @click="cancel()" class="py-2 px-4 border bg-white hover:bg-slate-200 active:bg-cyan-200 text-black rounded back">No</button>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 name: "ConfirmAction",
 data() {
  return {
   show: true,
  };
 },
 props: {
  confirmMessage: String,
 },
 methods: {
  confirm() {
    this.$emit('confirmed');
  },
  cancel() {
    this.$emit('canceled');
  }
 },
};
</script>